import Link from "next/link";
import { VFC } from "react";

interface FooterProps {
  home: boolean;
}

export const Footer: VFC<FooterProps> = ({ home = false }) => {
  return (
    <footer className={`l-footer ${home ? "-home" : ""}`}>
      <div className="l-footer__inner">
        <div className="p-ft">
          <div className="p-ft__cols">
            <div className="p-ft__colsCol">
              <p className="p-ft__logo">
                <a href="/" className="u-over">
                  <img src="/assets/img/common/logo2.svg" alt="ルーツしまね" />
                </a>
              </p>
            </div>
            <div className="p-ft__colsCol">
              <p className="p-ft__btn">
                <a href="/mypage/profile/" className="u-over">
                  MY PAGE
                </a>
              </p>
              <ul className="p-ft__sns">
                <li>
                  <a
                    href="https://lin.ee/cylP4fZ"
                    className="u-over"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/assets/img/common/icon-sns-li.png" alt="Line" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/rootsshimane/?hl=ja"
                    className="u-over"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/img/common/icon-sns-ig.png"
                      alt="Instagram"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/rootsshimane"
                    className="u-over"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/img/common/icon-sns-tw.png"
                      alt="Twitter"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/rootsshimane/"
                    className="u-over"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/img/common/icon-sns-fb.png"
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://note.com/rootsshimane/"
                    className="u-over"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="/assets/img/common/icon-sns-no.png" alt="Note" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="p-ft__colsCol">
              <nav className="p-ft__nav">
                <ul>
                  <li>
                    <Link href="/">
                      <a>ホーム</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/slack">
                      <a>使い方</a>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link href="/event">
                      <a>イベント</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/intern">
                      <a>インターン</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/article">
                      <a>読みもの</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/member/student">
                      <a>メンバー</a>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a
                      href="https://forms.gle/BSTGnazE3dd4L1916"
                      target="_blank"
                      rel="noreferrer"
                    >
                      お問い合わせ
                    </a>
                  </li>
                  <li>
                    <a href="">運営会社</a>
                  </li>
                  <li>
                    <Link href="/terms">
                      <a>利用規約</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/privacy">
                      <a>プライバシーポリシー</a>
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
