import Head from "next/head";
import Link from "next/link";
import { ReactNode, VFC } from "react";
import { ToastContainer } from "react-toastify";
import { Footer } from "./front/Footer";
import { Header } from "./front/Header";

interface Props {
  children: ReactNode;
  title: string;
  home?: boolean;
  bg?: boolean;
}

export const Layout: VFC<Props> = ({
  children,
  title = "Welcome to Next.js",
  home = false,
  bg = true,
}) => {
  return (
    <div className="l-body">
      <Head>
        <title>{title}</title>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;700&display=swap"
          rel="stylesheet"
        ></link>
      </Head>
      {bg && <div className="p-bodyBg"></div>}
      <div className="l-body__inner">
        <Header home={home} />
        <main className="l-main">
          {children}
          <ToastContainer />
        </main>
        <Footer home={home} />
        <div className="u-sp"></div>
      </div>
    </div>
  );
};
