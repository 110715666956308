import Link from "next/link";
import { VFC } from "react";

interface HeaderProps {
  home: boolean;
}

export const Header: VFC<HeaderProps> = ({ home = false }) => {
  return (
    <header className={`l-header ${home ? "-home" : ""}`}>
      <div className="l-header__fix">
        <div className="l-header__inner">
          <div className="p-hd">
            <div className="p-hd__cols">
              <div className="p-hd__colsCol">
                <div className="p-hd__logo">
                  <h1 className="p-hd__logoTxt">
                    <a href="/">
                      <img
                        src="/assets/img/common/logo.svg"
                        alt="ルーツしまね"
                      />
                    </a>
                  </h1>
                </div>
              </div>
              <div className="p-hd__colsCol">
                <nav className="p-hd__nav">
                  <ul>
                    <li>
                      <Link href="/event">
                        <a className="u-over">EVENT</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/intern">
                        <a className="u-over">INTERN</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/article">
                        <a className="u-over">MAGAZINE</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/member/student">
                        <a className="u-over">MEMBER</a>
                      </Link>
                    </li>
                    <li className="-btn -btn1">
                      <Link href="/regist">
                        <a className="-btn -btn1 u-over">JOIN</a>
                      </Link>
                    </li>
                    <li className="-btn -btn2">
                      <a className="-btn -btn2 u-over" href="/mypage/profile/">
                        MY PAGE
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            <div className="p-hd__spMenu u-spLg">
              <a href="javascript:void(0);">
                <span></span>
                <span></span>
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
